export default {
  badge: 'Insignă',
  close: 'Închideți',
  dataIterator: {
    noResultsText: 'Nu s-au găsit înregistrări corespunzătoare',
    loadingText: 'Se încarcă articolele...',
  },
  dataTable: {
    itemsPerPageText: 'Rânduri pe pagină:',
    ariaLabel: {
      sortDescending: 'Sortate descendent.',
      sortAscending: 'Sortate ascendent.',
      sortNone: 'Nesortate.',
      activateNone: 'Activați pentru a elimina sortarea.',
      activateDescending: 'Activați pentru a sorta descendent.',
      activateAscending: 'Activați pentru a sorta ascendent.',
    },
    sortBy: 'Sortați după',
  },
  dataFooter: {
    itemsPerPageText: 'Articole pe pagină:',
    itemsPerPageAll: 'Toate',
    nextPage: 'Pagina următoare',
    prevPage: 'Pagina anterioară',
    firstPage: 'Prima pagină',
    lastPage: 'Ultima pagină',
    pageText: '{0}-{1} din {2}',
  },
  datePicker: {
    itemsSelected: '{0} selectate',
    nextMonthAriaLabel: 'Luna următoare',
    nextYearAriaLabel: 'Anul următor',
    prevMonthAriaLabel: 'Luna anterioară',
    prevYearAriaLabel: 'Anul anterior',
  },
  noDataText: 'Nu există date disponibile',
  carousel: {
    prev: 'Grafica anterioară',
    next: 'Grafica următoare',
    ariaLabel: {
      delimiter: 'Slide carusel {0} din {1}',
    },
  },
  calendar: {
    moreEvents: 'încă {0}',
  },
  input: {
    clear: 'Clear {0}',
    prependAction: '{0} prepended action',
    appendAction: '{0} appended action',
  },
  fileInput: {
    counter: '{0} fișiere',
    counterSize: '{0} fișiere ({1} în total)',
  },
  timePicker: {
    am: 'AM',
    pm: 'PM',
  },
  pagination: {
    ariaLabel: {
      root: 'Navigare prin paginare',
      next: 'Pagina următoare',
      previous: 'Pagina anterioară',
      page: 'Mergeți la pagina {0}',
      currentPage: 'Pagina curentă, pagina {0}',
      first: 'First page',
      last: 'Last page',
    },
  },
  rating: {
    ariaLabel: {
      item: 'Rating de {0} din {1}',
    },
  },
}
